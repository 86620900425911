var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mainpage"},[_c('div',{staticClass:"head"},[_c('div',{staticClass:"head-title"},[_vm._v("预习资料详情")]),_c('div',{staticClass:"retutnbt",on:{"click":_vm.toback}},[_vm._v("返回")])]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"maincontain"},[_c('div',{staticClass:"main-contain"},[_c('div',{staticClass:"contain"},[_c('el-table',{ref:"singleTable",staticClass:"Table",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"highlight-current-row":"","header-cell-style":{
            'font-size': '15px',
            color: '#666666',
            'font-weight': 'bold',
            background: '#F7F7F7',
          },"row-style":{
            'font-size': '15px',
            color: '#222222',
            'font-weight': '400',
          }}},[_c('el-table-column',{attrs:{"label":"课程","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v(" "+_vm._s(scope.row.course_lib_name ? scope.row.course_lib_name : "-")+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"实验项目","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v(" "+_vm._s(scope.row.experiment_name ? scope.row.experiment_name : "-")+" ")])]}}])})],1)],1)]),_c('div',{staticClass:"main-contain"},[_c('div',{staticClass:"title2"},[_vm._v("预习资料:")]),_c('div',{staticClass:"contain"},[_c('el-table',{ref:"singleTable",staticClass:"Table",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData2,"highlight-current-row":"","header-cell-style":{
            'font-size': '15px',
            color: '#666666',
            'font-weight': 'bold',
            background: '#F7F7F7',
          },"row-style":{
            'font-size': '15px',
            color: '#222222',
            'font-weight': '400',
          }}},[_c('el-table-column',{attrs:{"label":"文件名"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"item-left"},[_c('el-image',{staticClass:"elimage",attrs:{"src":_vm.icon1}}),_c('div',{staticClass:"title"},[_vm._v(_vm._s(scope.row.name))])],1)]}}])}),_c('el-table-column',{attrs:{"label":"大小"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"item-middle"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(scope.row.size))])])]}}])}),_c('el-table-column',{attrs:{"label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"item-right"},[_c('div',{staticClass:"bt",on:{"click":function($event){return _vm.handelCheck(scope.row)}}},[_vm._v("查看")]),_c('div',{staticClass:"line2"}),_c('div',{staticClass:"bt",on:{"click":function($event){return _vm.handelDownload(scope.row)}}},[_vm._v("下载")])])]}}])})],1)],1)])]),_c('el-dialog',{attrs:{"title":"文件预览","visible":_vm.dialogVisible,"width":"50%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[(_vm.flag)?_c('FileViews',{attrs:{"url":_vm.url,"type":_vm.file_type}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }